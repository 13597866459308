const colorHasPriceRange = (variants, isCollection) => {
  if (isCollection) {
    return true;
  }

  if (!variants || !Array.isArray(variants) || !variants.length) {
    return false;
  }

  const uniquePrices = new Set();

  for (const variant of variants) {
    if (variant.available) {
      const currentVariantPrice = variant.sale_price ?? variant.price;
      uniquePrices.add(currentVariantPrice);
    }
  }

  return uniquePrices.size > 1;
};
export default colorHasPriceRange;
